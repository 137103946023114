.balance_row {
  width: 100%;
  margin-bottom: 15px;
  background-color: #96fac5;
  height: calc((280px - 45px) / 4);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 15px;
  text-decoration: none;
  position: relative;

  &-header {
    font-family: Ubuntu, sans-serif;
    color: #999999;
    font-size: .85rem;
    font-weight: 500;
  }

  &-value {
    font-family: Ubuntu, sans-serif;
    color: #333333;
    font-size: .95rem;
    font-weight: 500;
  }
}

.balance_col {
  margin-bottom: 15px;
  background-color: #96fac5;
  height: calc((280px - 45px) / 4);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  color: #008279;

  &._disabled {
    background-color: #aaaaaa;
    color: #ffffff;
  }
}