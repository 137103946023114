.main-block {
  width: 100%;
  background-color: #ffffff;
  min-height: 280px;
  border-radius: 25px;
  padding: 15px 25px;
  position: relative;
  height: auto;

  &__header {
    font-family: Ubuntu, sans-serif;
    font-weight: 500;
    color: #a1a1a1;
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &-number {
      color: #47c999;
      font-size: 1rem;
      font-weight: 500;
    }
  }
}

.chart_data {

  &-delta {
    font-family: Ubuntu, sans-serif;
    color: #47c999;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1;
    position: absolute;
    top: 45px;
  }

  &-dates {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
      font-family: Montserrat, sans-serif;
      font-size: .75rem;
    }
  }
}

.period-button {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 25px;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  color: #a1a1a1;
  font-weight: 500;
  cursor: pointer;

  &._active {
    background-color: #96fac5;
  }
}