.wallet_card {
  width: 100%;
  background-color: #96fac5;
  height: 280px;
  border-radius: 25px;

  position: relative;
  padding-top: 25px;

  &-stripe {
    width: 100%;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #47c999;
    padding: 0 15px;

    span {
      font-weight: 600;
      color: #ffffff;
    }
  }

  &-balance {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 30px auto 0;
    width: fit-content;
    text-decoration: none;

    .stt-empty {
      width: 50px;
      display: block;
    }

    .stt-balance {
      font-size: 28px;
      font-weight: 500;
      color: #444444!important;
      font-family: Ubuntu, sans-serif;
    }

    .stt-arrow {
      width: 50px;
      height: 42px;
      font-size: 28px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      color: #47c999;
    }
  }

  .help-usdt-balance {
    text-align: center;
    font-size: .9rem;
    font-weight: 500;
    color: #008279;
    font-family: Ubuntu, sans-serif;
    margin-bottom: 36px;
  }

  .additional-balances {
    margin-top: 15px;
    padding-left: 25px;

    div {
      font-family: Ubuntu, sans-serif;
      font-size: .85rem;
      font-weight: 500;
      margin-bottom: 5px;
      width: 40%;
      background-color: rgba(255,255,255,.4);
      color: #47c999;
      padding: 5px 15px;
      border-radius: 15px;


      span {
        font-family: Montserrat, sans-serif;
        width: 45px;
        display: inline-block;
      }
    }
  }
}

.wallet_link {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #96fac5;
  border-radius: 25px;
  padding: 10px 20px;
  text-decoration: none;
  color: #008279!important;
  font-weight: 500;

  span {
    font-size: 20px;
  }
}