* {
  font-family: 'Ubuntu', sans-serif;
}

@font-face {
  font-family: 'Mittleschrift-Caps';
  src:url('fonts/numbers/Mittleschrift-Caps.ttf.woff') format('woff'),
  url('fonts/numbers/Mittleschrift-Caps.ttf.svg#Mittleschrift-Caps') format('svg'),
  url('fonts/numbers/Mittleschrift-Caps.ttf.eot'),
  url('fonts/numbers/Mittleschrift-Caps.ttf.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: #f8f8f8;
}

.container-sm {
  background-color: #f8f8f8;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 0 25px 0;
}

.App.full-screen {
  height: 100vh;
  padding: 0;
}

@media (max-width: 450px) {
  .App {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.App h1 {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.meta-btn {
  display: flex;
  align-items: center;
}

.cards {
  width: 100%;
}

.cards>div {
  /*margin-bottom: 40px;*/
}

.cards.bottom {
  margin: 0!important;
}

@media (max-width: 767px) {
  /*.card-1 {*/
  /*  order: 1;*/
  /*}*/

  /*.card-2 {*/
  /*  order: 2;*/
  /*  margin-bottom: 0!important;*/
  /*}*/

  /*.card-3 {*/
  /*  order: 6;*/
  /*}*/

  /*.card-4 {*/
  /*  order: 8;*/
  /*}*/

  /*.card-5 {*/
  /*  order: 9;*/
  /*}*/

  /*.card-6 {*/
  /*  order: 10*/
  /*}*/

  /*.card-7 {*/
  /*  order: 7*/
  /*}*/

  /*.card-8 {*/
  /*  order: 4*/
  /*}*/

  /*.card-9 {*/
  /*  order: 5*/
  /*}*/

  /*.card-10 {*/
  /*  order: 11*/
  /*}*/

  /*.card-11 {*/
  /*  order: 12*/
  /*}*/

  /*.card-12 {*/
  /*  order: 13*/
  /*}*/

  /*.card-13 {*/
  /*  order: 3;*/
  /*}*/

  .cards > .col-12 {
    padding: 0 15px;
  }


  .card-13 > div > .info-card {
    height: auto!important;
  }

  .card-13 > div > .info-card > .info-text {
    height: auto!important;
  }

  .history-info-card {
    /*height: 30px!important;*/
  }

  .links-holder {
    padding: 25px 0!important;
  }


}

.partners {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 270px;
}

.partner {
  width: 180px;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 4px 6px 1px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0 4px 6px 1px rgba(34, 60, 80, 0.2);
  box-shadow: 0 4px 6px 1px rgba(34, 60, 80, 0.2);
  margin: 0 10px;
}

.partner img {
  height: 60px;
  margin-bottom: 15px;
}

.partner p {
  font-size: 11px;
  color: #333333;
  margin-bottom: 0;
}

.preloader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: ease-in-out .5s;
  background-color: #99999990;
  border-radius: 25px;
}

.preloading-modal .modal-content {
  background-color: #ffffff50;
}

.chainLink {
  display: block;
  color: #ffffff;
  position: relative;
  margin-top: 30px;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
}

.chainLink:hover {
  color: #ffffff;
}

.history_wrapper::-webkit-scrollbar {
  /*height: 5px;*/
}

.history_wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}

.history_wrapper {
  scrollbar-width: thin;
}

.close-common-btn {
  background-color: #777777!important;
  color: #ffffff!important;
}

.action-common-btn {
  background-color: #222222!important;
  color: #ffffff!important;
  padding: .5rem 1.5rem!important;
}

.login-btn {
  position: relative;
  cursor: pointer;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 55px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  font-weight: 500;
  font-size: 1.3rem;
  font-family: 'Barlow Condensed', sans-serif;
  letter-spacing: 7px;
  padding-left: 35px;
  color: #888888;
}

.login-btn img {
  height: 50px;
  position: absolute;
  left: 10px;
}

.complete-toast {
  background-color: #ffffff;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.complete-toast .toast-body {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.complete-toast .toast-body i {
  margin: 40px;
  background-color: transparent;
  text-shadow: 5px 5px 10px #aaaaaa;
}

.complete-toast .toast-body p {
  color: #31d59b!important;
  font-size: 1.1rem;
  margin-top: 0;
}

.complete-toast .toast-body p.complete-toast-text {
  color: #a1a1a1!important;
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 0;
  text-align: center;
}

.complete-toast p.toast-err {
  color: #ff968f!important;
}

.eth-card {
  box-shadow: 10px 2px 12px 4px #e0e0e0;
}

.contact-btn {
  color: #008279 !important;
  font-size: 13px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 150px;
  margin: 30px auto 80px;
  padding: 15px 25px;
  border-radius: 50px;
  background-color: #96fac5;
  text-align: center;
  font-weight: 500;
}