.wallet__header {
  padding-top: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.wallet__header .logo-holder {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.wallet__header .non-business {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100px;
}
@media (max-width: 767px) {
  .wallet__header .logo-holder {
    padding-left: 15px;
  }
  .wallet__header .logo-holder p {
    display: none;
  }
  .wallet__header .non-business {
    justify-content: space-between;
  }
}
.wallet__header .logo-holder p {
  margin-left: 10px;
  color: #47c999;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
}
.wallet__header-telegram {
  height: 39px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .wallet__header-telegram {
    padding-right: 15px;
  }
}
.wallet__header-telegram .notifications-btn {
  cursor: pointer;
}
.wallet__header-telegram .notifications-btn i {
  font-size: 28px;
  color: #333333;
  transition: text-shadow ease 0.2s;
}
.wallet__header-telegram .notifications-btn i:hover {
  text-shadow: 4px 4px 8px #aaa;
}

.telegram-modal .modal-content {
  box-shadow: 14px 14px 22px 10px #707070;
  border-radius: 15px;
  border-color: transparent;
}
.telegram-modal .modal-content .modal-body {
  padding: 0;
  text-align: center;
}
.telegram-modal .modal-content .modal-body .stt_modal_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.telegram-modal .modal-content .modal-body .stt_modal_header .notification_header {
  font-family: Ubuntu, sans-serif;
  color: #ffffff;
  width: 165px;
  height: 45px;
  background-color: #a1a1a1;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
.telegram-modal .modal-content .modal-body .stt_modal_header .close_btn {
  width: 50px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.telegram-modal .modal-content .modal-body .stt_modal_header .close_btn i {
  color: #a1a1a1;
  font-size: 28px;
}
.telegram-modal .modal-content .modal-body p {
  margin-top: 70px;
  margin-bottom: 40px;
  font-weight: 600;
}
.telegram-modal .modal-content .modal-body h3 {
  font-family: Ubuntu, sans-serif;
  color: #008279;
}
.telegram-modal .modal-content .modal-body .modal-button {
  margin-bottom: 30px;
  width: 150px;
  height: 70px;
  border-radius: 35px;
  font-weight: 600;
  background-color: #96fac5 !important;
  color: #008279 !important;
  border-color: transparent !important;
}
.telegram-modal.telegram-set-modal .modal-content {
  background-color: #96fac5;
}
.telegram-modal.telegram-set-modal .modal-content .stt_modal_header .notification_header {
  background-color: rgba(255, 255, 255, 0.6);
  color: #47c999;
}
.telegram-modal.telegram-set-modal .modal-content .stt_modal_header .close_btn i {
  color: #ffffff;
  opacity: 0.9;
}
.telegram-modal.telegram-set-modal .modal-content .modal-button {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

