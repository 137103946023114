.insurance-item {
  /*background-color: #ededed;*/
  border-radius: 25px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
}
.insurance-item_icon {
  position: relative;
  width: 36px;
  margin-right: 5px;
}
.insurance-item_data-title {
  font-size: 0.8rem;
  color: #888888;
}
.insurance-item_data-rate {
  font-size: 0.75rem;
}

.insurance_rows {
  margin-top: 10px;
}

.insurance_total {
  font-weight: 500;
  font-size: 1.1rem;
  position: absolute;
  bottom: 15px;
  right: 30px;
  color: #47c999;
}

