.balance_row {
  width: 100%;
  margin-bottom: 15px;
  background-color: #96fac5;
  height: 58.75px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 15px;
  text-decoration: none;
  position: relative;
}
.balance_row-header {
  font-family: Ubuntu, sans-serif;
  color: #999999;
  font-size: 0.85rem;
  font-weight: 500;
}
.balance_row-value {
  font-family: Ubuntu, sans-serif;
  color: #333333;
  font-size: 0.95rem;
  font-weight: 500;
}

.balance_col {
  margin-bottom: 15px;
  background-color: #96fac5;
  height: 58.75px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  color: #008279;
}
.balance_col._disabled {
  background-color: #aaaaaa;
  color: #ffffff;
}

