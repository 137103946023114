.wallet__header {
  padding-top: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .logo-holder {
    display: flex;
    align-items: center;
    flex-direction: row;
  }


  .non-business {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
  }

  @media (max-width: 767px) {
    .logo-holder {
      padding-left: 15px;
    }
    .logo-holder p {
      display: none;
    }
    .non-business {
      justify-content: space-between;
    }
  }

  .logo-holder p {
    margin-left: 10px;
    color: #47c999;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 0;
  }

  &-telegram {
    height: 39px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 767px) {
      padding-right: 15px;
    }

    .notifications-btn {
      cursor: pointer;

    }

    .notifications-btn i {
      font-size: 28px;
      color: #333333;
      transition: text-shadow ease .2s;

      &:hover {
        text-shadow: 4px 4px 8px #aaa;
      }
    }
  }
}


.telegram-modal {
  .modal-content {
    box-shadow: 14px 14px 22px 10px #707070;
    border-radius: 15px;
    border-color: transparent;

    .modal-body {
      padding: 0;
      text-align: center;

      .stt_modal_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .notification_header {
          font-family: Ubuntu, sans-serif;
          color: #ffffff;
          width: 165px;
          height: 45px;
          background-color: #a1a1a1;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
        }
        .close_btn {
          width: 50px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          i {
            color: #a1a1a1;
            font-size: 28px;
          }
        }

      }

      p {
        margin-top: 70px;
        margin-bottom: 40px;
        font-weight: 600;
      }

      h3 {
        font-family: Ubuntu, sans-serif;
        color: #008279;
      }

      .modal-button {
        margin-bottom: 30px;
        width: 150px;
        height: 70px;
        border-radius: 35px;
        font-weight: 600;
        background-color: #96fac5!important;
        color: #008279!important;
        border-color: transparent!important;
      }
    }
  }

  &.telegram-set-modal {

    .modal-content {
      background-color: #96fac5;

      .stt_modal_header {

        .notification_header {
          background-color: rgba(255,255,255,.6);
          color: #47c999;
        }

        .close_btn {

          i {
            color: #ffffff;
            opacity: .9;
          }
        }
      }
      .modal-button {
        background-color: rgba(255,255,255,.6)!important;
      }
    }

  }
}
