.bubbles {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: #31d59b; }

.bubble {
    position: absolute;
    left: var(--bubble-left-offset);
    bottom: -75%;
    display: block;
    width: var(--bubble-radius);
    height: var(--bubble-radius);
    border-radius: 50%;
    animation: float-up var(--bubble-float-duration) var(--bubble-float-delay) ease-in infinite; }
@media (max-width: 440px) {
    .bubble {
        width: var(--bubble-mobile-radius);
        height: var(--bubble-mobile-radius); } }
.bubble::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--bubble-bg);
    border-radius: 50%;
    border-top-right-radius: 0;
    animation: var(--bubble-sway-type) var(--bubble-sway-duration) var(--bubble-sway-delay) ease-in-out alternate infinite; }
.bubble:nth-child(0) {
    --bubble-left-offset: 58vw;
    --bubble-radius: 18vw;
    --bubble-mobile-radius: 31vw;
    --bubble-float-duration: 10s;
    --bubble-sway-duration: 13s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(255,255,255,.2)
; }
.bubble:nth-child(1) {
    --bubble-left-offset: 50vw;
    --bubble-radius: 19vw;
    --bubble-mobile-radius: 34vw;
    --bubble-float-duration: 10s;
    --bubble-sway-duration: 16s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(255,255,255,.1)
; }
.bubble:nth-child(2) {
    --bubble-left-offset: 30vw;
    --bubble-radius: 14vw;
    --bubble-mobile-radius: 27vw;
    --bubble-float-duration: 11s;
    --bubble-sway-duration: 10s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(255,255,255,.2)
; }
.bubble:nth-child(3) {
    --bubble-left-offset: 27vw;
    --bubble-radius: 14vw;
    --bubble-mobile-radius: 20vw;
    --bubble-float-duration: 14s;
    --bubble-sway-duration: 11s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(255,255,255,.1)
; }
.bubble:nth-child(4) {
    --bubble-left-offset: 70vw;
    --bubble-radius: 10vw;
    --bubble-mobile-radius: 31vw;
    --bubble-float-duration: 16s;
    --bubble-sway-duration: 5s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(5) {
    --bubble-left-offset: 35vw;
    --bubble-radius: 18vw;
    --bubble-mobile-radius: 36vw;
    --bubble-float-duration: 20s;
    --bubble-sway-duration: 10s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(255,255,255,.2)
; }
.bubble:nth-child(6) {
    --bubble-left-offset: 44vw;
    --bubble-radius: 10vw;
    --bubble-mobile-radius: 44vw;
    --bubble-float-duration: 17s;
    --bubble-sway-duration: 13s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(7) {
    --bubble-left-offset: 49vw;
    --bubble-radius: 11vw;
    --bubble-mobile-radius: 24vw;
    --bubble-float-duration: 18s;
    --bubble-sway-duration: 13s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(8) {
    --bubble-left-offset: 95vw;
    --bubble-radius: 14vw;
    --bubble-mobile-radius: 53vw;
    --bubble-float-duration: 15s;
    --bubble-sway-duration: 15s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(255,255,255,.1)
; }
.bubble:nth-child(9) {
    --bubble-left-offset: 54vw;
    --bubble-radius: 8vw;
    --bubble-mobile-radius: 33vw;
    --bubble-float-duration: 13s;
    --bubble-sway-duration: 15s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(255,255,255,.2)
; }
.bubble:nth-child(10) {
    --bubble-left-offset: 49vw;
    --bubble-radius: 14vw;
    --bubble-mobile-radius: 36vw;
    --bubble-float-duration: 18s;
    --bubble-sway-duration: 16s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(255,255,255,.1)
; }
.bubble:nth-child(11) {
    --bubble-left-offset: 41vw;
    --bubble-radius: 15vw;
    --bubble-mobile-radius: 34vw;
    --bubble-float-duration: 18s;
    --bubble-sway-duration: 8s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(255,255,255,.1)
; }
.bubble:nth-child(12) {
    --bubble-left-offset: 20vw;
    --bubble-radius: 8vw;
    --bubble-mobile-radius: 27vw;
    --bubble-float-duration: 15s;
    --bubble-sway-duration: 4s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(13) {
    --bubble-left-offset: 70vw;
    --bubble-radius: 8vw;
    --bubble-mobile-radius: 45vw;
    --bubble-float-duration: 12s;
    --bubble-sway-duration: 12s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(14) {
    --bubble-left-offset: 12vw;
    --bubble-radius: 19vw;
    --bubble-mobile-radius: 38vw;
    --bubble-float-duration: 22s;
    --bubble-sway-duration: 14s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(255,255,255,.2)
; }
.bubble:nth-child(15) {
    --bubble-left-offset: 91vw;
    --bubble-radius: 10vw;
    --bubble-mobile-radius: 41vw;
    --bubble-float-duration: 19s;
    --bubble-sway-duration: 10s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(255,255,255,.1)
; }
.bubble:nth-child(16) {
    --bubble-left-offset: 56vw;
    --bubble-radius: 8vw;
    --bubble-mobile-radius: 23vw;
    --bubble-float-duration: 17s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(17) {
    --bubble-left-offset: 17vw;
    --bubble-radius: 16vw;
    --bubble-mobile-radius: 27vw;
    --bubble-float-duration: 9s;
    --bubble-sway-duration: 14s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(255,255,255,.2)
; }
.bubble:nth-child(18) {
    --bubble-left-offset: 57vw;
    --bubble-radius: 17vw;
    --bubble-mobile-radius: 42vw;
    --bubble-float-duration: 17s;
    --bubble-sway-duration: 11s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(255,255,255,.2)
; }
.bubble:nth-child(19) {
    --bubble-left-offset: 51vw;
    --bubble-radius: 17vw;
    --bubble-mobile-radius: 45vw;
    --bubble-float-duration: 22s;
    --bubble-sway-duration: 16s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(20) {
    --bubble-left-offset: 75vw;
    --bubble-radius: 13vw;
    --bubble-mobile-radius: 54vw;
    --bubble-float-duration: 11s;
    --bubble-sway-duration: 8s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(21) {
    --bubble-left-offset: 8vw;
    --bubble-radius: 20vw;
    --bubble-mobile-radius: 43vw;
    --bubble-float-duration: 11s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(22) {
    --bubble-left-offset: 28vw;
    --bubble-radius: 18vw;
    --bubble-mobile-radius: 35vw;
    --bubble-float-duration: 17s;
    --bubble-sway-duration: 10s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(255,255,255,.2)
; }
.bubble:nth-child(23) {
    --bubble-left-offset: 3vw;
    --bubble-radius: 17vw;
    --bubble-mobile-radius: 31vw;
    --bubble-float-duration: 10s;
    --bubble-sway-duration: 13s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(255,255,255,.1)
; }
.bubble:nth-child(24) {
    --bubble-left-offset: 57vw;
    --bubble-radius: 11vw;
    --bubble-mobile-radius: 39vw;
    --bubble-float-duration: 17s;
    --bubble-sway-duration: 5s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(25) {
    --bubble-left-offset: 94vw;
    --bubble-radius: 17vw;
    --bubble-mobile-radius: 45vw;
    --bubble-float-duration: 18s;
    --bubble-sway-duration: 10s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(26) {
    --bubble-left-offset: 1vw;
    --bubble-radius: 9vw;
    --bubble-mobile-radius: 53vw;
    --bubble-float-duration: 14s;
    --bubble-sway-duration: 8s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(255,255,255,.1)
; }
.bubble:nth-child(27) {
    --bubble-left-offset: 49vw;
    --bubble-radius: 19vw;
    --bubble-mobile-radius: 49vw;
    --bubble-float-duration: 17s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(255,255,255,.2)
; }
.bubble:nth-child(28) {
    --bubble-left-offset: 7vw;
    --bubble-radius: 19vw;
    --bubble-mobile-radius: 54vw;
    --bubble-float-duration: 11s;
    --bubble-sway-duration: 9s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(255,255,255,.2)
; }
.bubble:nth-child(29) {
    --bubble-left-offset: 85vw;
    --bubble-radius: 10vw;
    --bubble-mobile-radius: 46vw;
    --bubble-float-duration: 11s;
    --bubble-sway-duration: 13s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(255,255,255,.1)
; }
.bubble:nth-child(30) {
    --bubble-left-offset: 81vw;
    --bubble-radius: 19vw;
    --bubble-mobile-radius: 34vw;
    --bubble-float-duration: 13s;
    --bubble-sway-duration: 16s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(31) {
    --bubble-left-offset: 32vw;
    --bubble-radius: 11vw;
    --bubble-mobile-radius: 30vw;
    --bubble-float-duration: 18s;
    --bubble-sway-duration: 6s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(255,255,255,.2)
; }
.bubble:nth-child(32) {
    --bubble-left-offset: 50vw;
    --bubble-radius: 17vw;
    --bubble-mobile-radius: 27vw;
    --bubble-float-duration: 14s;
    --bubble-sway-duration: 8s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(33) {
    --bubble-left-offset: 58vw;
    --bubble-radius: 17vw;
    --bubble-mobile-radius: 27vw;
    --bubble-float-duration: 19s;
    --bubble-sway-duration: 11s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(34) {
    --bubble-left-offset: 43vw;
    --bubble-radius: 10vw;
    --bubble-mobile-radius: 37vw;
    --bubble-float-duration: 18s;
    --bubble-sway-duration: 9s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(255,255,255,.1)
; }
.bubble:nth-child(35) {
    --bubble-left-offset: 71vw;
    --bubble-radius: 10vw;
    --bubble-mobile-radius: 31vw;
    --bubble-float-duration: 10s;
    --bubble-sway-duration: 16s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(36) {
    --bubble-left-offset: 2vw;
    --bubble-radius: 9vw;
    --bubble-mobile-radius: 23vw;
    --bubble-float-duration: 9s;
    --bubble-sway-duration: 10s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(37) {
    --bubble-left-offset: 17vw;
    --bubble-radius: 12vw;
    --bubble-mobile-radius: 23vw;
    --bubble-float-duration: 14s;
    --bubble-sway-duration: 10s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(255,255,255,.1)
; }
.bubble:nth-child(38) {
    --bubble-left-offset: 36vw;
    --bubble-radius: 14vw;
    --bubble-mobile-radius: 22vw;
    --bubble-float-duration: 8s;
    --bubble-sway-duration: 13s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(255,255,255,.1)
; }
.bubble:nth-child(39) {
    --bubble-left-offset: 7vw;
    --bubble-radius: 14vw;
    --bubble-mobile-radius: 27vw;
    --bubble-float-duration: 17s;
    --bubble-sway-duration: 16s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(40) {
    --bubble-left-offset: 36vw;
    --bubble-radius: 14vw;
    --bubble-mobile-radius: 27vw;
    --bubble-float-duration: 22s;
    --bubble-sway-duration: 14s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(255,255,255,.1)
; }
.bubble:nth-child(41) {
    --bubble-left-offset: 20vw;
    --bubble-radius: 8vw;
    --bubble-mobile-radius: 25vw;
    --bubble-float-duration: 17s;
    --bubble-sway-duration: 14s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(255,255,255,.2)
; }
.bubble:nth-child(42) {
    --bubble-left-offset: 29vw;
    --bubble-radius: 12vw;
    --bubble-mobile-radius: 22vw;
    --bubble-float-duration: 19s;
    --bubble-sway-duration: 8s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(255,255,255,.2)
; }
.bubble:nth-child(43) {
    --bubble-left-offset: 1vw;
    --bubble-radius: 20vw;
    --bubble-mobile-radius: 42vw;
    --bubble-float-duration: 20s;
    --bubble-sway-duration: 5s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(255,255,255,.2)
; }
.bubble:nth-child(44) {
    --bubble-left-offset: 32vw;
    --bubble-radius: 8vw;
    --bubble-mobile-radius: 53vw;
    --bubble-float-duration: 18s;
    --bubble-sway-duration: 8s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(45) {
    --bubble-left-offset: 36vw;
    --bubble-radius: 15vw;
    --bubble-mobile-radius: 39vw;
    --bubble-float-duration: 21s;
    --bubble-sway-duration: 13s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(255,255,255,.1)
; }
.bubble:nth-child(46) {
    --bubble-left-offset: 77vw;
    --bubble-radius: 11vw;
    --bubble-mobile-radius: 38vw;
    --bubble-float-duration: 21s;
    --bubble-sway-duration: 11s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(255,255,255,.1)
; }
.bubble:nth-child(47) {
    --bubble-left-offset: 5vw;
    --bubble-radius: 17vw;
    --bubble-mobile-radius: 54vw;
    --bubble-float-duration: 9s;
    --bubble-sway-duration: 10s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(255,255,255,.1)
; }
.bubble:nth-child(48) {
    --bubble-left-offset: 22vw;
    --bubble-radius: 10vw;
    --bubble-mobile-radius: 29vw;
    --bubble-float-duration: 10s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(49) {
    --bubble-left-offset: 14vw;
    --bubble-radius: 17vw;
    --bubble-mobile-radius: 49vw;
    --bubble-float-duration: 12s;
    --bubble-sway-duration: 9s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-right-to-left;
    --bubble-bg: rgba(0,0,0,.02)
; }
.bubble:nth-child(50) {
    --bubble-left-offset: 6vw;
    --bubble-radius: 20vw;
    --bubble-mobile-radius: 38vw;
    --bubble-float-duration: 17s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 0s;
    --bubble-sway-type: sway-left-to-right;
    --bubble-bg: rgba(0,0,0,.02)
; }

@keyframes float-up {
    to {
        transform: translateY(-175vh); } }
@keyframes sway-left-to-right {
    from {
        transform: translateX(-100%); }
    to {
        transform: translateX(100%); } }
@keyframes sway-right-to-left {
    from {
        transform: translateX(100%); }
    to {
        transform: translateX(-100%); } }

